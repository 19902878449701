$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 0
        }, 1000);
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navigation
    // *
    // *
    $(".js-nav-btn").click(function(){
        $(this).toggleClass("active");
        $(".js-nav").toggleClass('active');
        $("html, body").toggleClass('freeze');
    });

    $(".js-nav a").click(function(){
        $(".js-nav-btn").trigger("click");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * slider
    // *
    // *
    var $slider = $(".js-slider");

    if ($slider.length) {
        $slider.each(function(){
            $(this).slick({
                lazyLoad: 'ondemand',
                infinite: true,
                fade: true,
                dots: false,
                arrows: false,
                autoplay: true,
                speed: 1000,
                autoplaySpeed: 1000,
                waitForAnimate: false,
                swipe: false,
                rows: 0,
                responsive: []
            });
        });

        // hide all hidden (nav specific) slides
        $slider.slick('slickFilter', '.js-filter-init');
    }

    $(".js-slider-nav-item").click(function(e){
        e.preventDefault();

        $(".js-slider-nav-item").removeClass("active");
        $(this).addClass("active");

        var filter = $(this).data("filter"),
            $slider = $(this).parents(".js-slider-wrapper").find(".js-slider");

        $slider.slick('slickUnfilter');
        $slider.slick('slickFilter', '.js-filter-' + filter);
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * slider
    // *
    // *
    var $sliderGallery = $(".js-slidergallery");

    if ($sliderGallery.length) {
        $sliderGallery.each(function(){
            $(this).slick({
                infinite: true,
                fade: false,
                dots: false,
                arrows: true,
                prevArrow: $(this).siblings(".js-slidergallery-prev"),
                nextArrow: $(this).siblings(".js-slidergallery-next"),
                autoplay: true,
                speed: 1000,
                autoplaySpeed: 2500,
                variableWidth: true,
                pauseOnHover: false,
            });
        });
    }



     // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * imageSlider
    // *
    // *
    var $imageSlider = $(".js-imageslider");

    if ($imageSlider.length) {
        $imageSlider.each(function(){
            $(this).slick({
                infinite: true,
                fade: true,
                dots: false,
                arrows: false,
                autoplay: true,
                speed: 1000,
                autoplaySpeed: 1000,
                waitForAnimate: false,
            });
        });
    }

});
